const state = () => ({
  api: null,
  auth: {
    POST: {
      sendCode: '/auth/sendCode',
      login: '/auth/login',
      me: '/auth/me',
      refresh: '/auth/refresh',
      logout: '/auth/logout',
    },
  },
  client: {
    GET: {
      profile: '/client/profile',
    },
    POST: {
      promocode: '/client/addPromoCode',
      sendAppLink: '/client/sendAppLink',
    },
    PATCH: {
      profile: '/client/profile',
    },
  },
  menu: {
    GET: {
      search: '/menu/items',
      single: '/menu/items/{id}',
      singleCollection: '/menu/collections/{type}/{target}',
      category: '/menu/categories/{id}',
      categories: '/menu/categories',
      topMenuCategories: '/menu/categories/topMenu',
    },
  },
  cart: {
    GET: {
      getCart: '/basket',
      getPromoCodes: '/basket/promoCodes',
    },
    POST: {
      addToCart: '/basket/item',
      // Изменить количество товаров в корзине
      changeQuantity: '/basket/items/{hash}/changeQuantity',
      // Изменить модицикатор у группы в корзине
      changeModification: '/basket/items/{hash}/editModification',
    },
    PATCH: {
      // Обновить данные корзины
      updateCart: '/basket',
      // Изменить адрес и время доставки
      changeCourier: '/basket/changeCourier',
      // Изменить адрес самовывоза
      changePickup: '/basket/changePickup',
      // Изменить тип оплаты и сумму размена
      changePayment: '/basket/changePayment',
      // Изменить или добавить промокод
      addPromoCode: '/basket/addPromoCode',
      // Обновить товар в корзине. Для увеличения количества товара и изменения модификаторов.
      updateCartItem: '/basket/item/{id}',

      // Отказаться от звонка администратора
      updateCallNotification: '/basket/updateCallNotification',
      // Оформить заказ
      createOrder: '/basket/createOrder',
      // Синхронизация корзин
      syncCart: '/basket/addItems',
    },
    DELETE: {
      deleteAll: '/basket/items',
      delete: '/basket/items/{hash}/delete',
    },
  },
  address: {
    GET: { address: '/client/address' },
    POST: { address: '/client/address' },
    PATCH: { address: '/client/address/{id}' },
    DELETE: { address: '/client/address/{id}' },
  },
  common: {
    GET: {
      suggestAddress: '/common/getSuggestAddress',
      suggestStreet: '/common/getSuggestStreet',
      suggestHouse: '/common/getSuggestHouse',
      checkAddressIsDeliveryByString: '/common/checkAddressIsDeliveryByString',
    },
  },
  orders: {
    GET: {
      getOrderById: '/orders/{id}',
      history: '/orders/getHistory',
      active: '/orders/getActive',
      getThankPageDetails: '/orders/thankPage/{id}',
    },
    POST: {
      repeatOrder: '/orders/{id}/repeat',
    },
  },
  information: {
    GET: {
      sliders: '/information/sliders',
      polygons: '/information/polygons',
      promotions: '/information/promotions',
      promotionsSingle: '/information/promotions/{id}',
      news: '/information/news',
      newsSingle: '/information/news/{id}',
      regions: '/information/regions',
      regionSingle: '/information/regions/item',
      regionById: '/information/regions/{id}',
      pickupPoints: '/information/pickupPoints',
      pickupPointsSingle: '/information/pickupPoints/slug/{slug}',
      deliveryOptions: '/information/delivery/options',
    },
  },
  geo: {
    GET: {
      deliveryMap: '/geo/delivery-map',
    },
  },
  favorites: {
    GET: {
      list: '/client/favorites',
    },
    POST: {
      addMenuItem: '/client/favorites/addMenuItem/{id}',
      deleteMenuItem: '/client/favorites/removeMenuItem/{id}',
    },
  },
  seo: {
    GET: {
      meta: '/seo/meta',
    },
  },
})

const getters = {
  // Auth
  authSendCode (state) {
    return state.api + state.auth.POST.sendCode
  },
  authLogin (state) {
    return state.api + state.auth.POST.login
  },
  authMe (state) {
    return state.api + state.auth.POST.me
  },
  authRefresh (state) {
    return state.api + state.auth.POST.refresh
  },
  authLogout (state) {
    return state.api + state.auth.POST.logout
  },
  // Client
  clientGetProfile (state) {
    return state.api + state.client.GET.profile
  },
  clientPatchProfile (state) {
    return state.api + state.client.PATCH.profile
  },
  clientPostPromoCode (state) {
    return state.api + state.client.POST.promocode
  },
  clientSendAppLink (state) {
    return state.api + state.client.POST.sendAppLink
  },
  // Menu
  menuSearch (state) {
    return state.api + state.menu.GET.search
  },
  // Получение конкретного блюда по id или slug(для SSR)
  menuSingle:
    state =>
      (id, type = 'id') => {
        return state.api + `/menu/items/${id}?type=${type}`
      },
  menuSingleCollection: state => (target, type, onlyList) => {
    let url =
      state.api +
      state.menu.GET.singleCollection
        .replace(/\{type\}/g, type)
        .replace(/\{target\}/g, target)
    if (onlyList) {
      url += '?relations=category'
    }
    return url
  },
  menuCategory (state) {
    return state.api + state.menu.GET.category
  },
  menuCategories (state) {
    return state.api + state.menu.GET.categories
  },
  topMenuCategories (state) {
    return state.api + state.menu.GET.topMenuCategories
  },
  // Cart
  cartGetCart (state) {
    return state.api + state.cart.GET.getCart
  },
  cartGetPromoCodes (state) {
    return state.api + state.cart.GET.getPromoCodes
  },
  cartAddToCart (state) {
    return state.api + state.cart.POST.addToCart
  },
  cartUpdateCart (state) {
    return state.api + state.cart.PATCH.updateCart
  },
  cartChangeCourier (state) {
    return state.api + state.cart.PATCH.changeCourier
  },
  cartChangePickup (state) {
    return state.api + state.cart.PATCH.changePickup
  },
  cartChangePayment (state) {
    return state.api + state.cart.PATCH.changePayment
  },
  cartAddPromoCode (state) {
    return state.api + state.cart.PATCH.addPromoCode
  },
  cartUpdateCartItem: state => (id) => {
    return state.api + state.cart.PATCH.updateCartItem.replace(/\{id\}/g, id)
  },
  cartUpdateCallNotification (state) {
    return state.api + state.cart.PATCH.updateCallNotification
  },
  cartCreateOrder (state) {
    return state.api + state.cart.PATCH.createOrder
  },
  cartSync (state) {
    return state.api + state.cart.PATCH.syncCart
  },
  cartDelete: state => (hash) => {
    return state.api + state.cart.DELETE.delete.replace(/\{hash\}/g, hash)
  },
  cartChangeQuantity: state => (params) => {
    return (
      state.api +
      state.cart.POST.changeQuantity.replace(/\{hash\}/g, params.hash)
    )
  },
  changeModification: state => (params) => {
    return (
      state.api +
      state.cart.POST.changeModification.replace(/\{hash\}/g, params.hash)
    )
  },
  cartDeleteAll (state) {
    return state.api + state.cart.DELETE.deleteAll
  },
  // Address
  addressGetAddresses (state) {
    return state.api + state.address.GET.address
  },
  addressCreateAddress (state) {
    return state.api + state.address.POST.address
  },
  addressEditAddress: state => (id) => {
    return state.api + state.address.PATCH.address.replace(/\{id\}/g, id)
  },
  addressDeleteAddress: state => (id) => {
    return state.api + state.address.DELETE.address.replace(/\{id\}/g, id)
  },
  // Common
  getSuggestAddress (state) {
    return state.api + state.common.GET.suggestAddress
  },
  getSuggestStreet (state) {
    return state.api + state.common.GET.suggestStreet
  },
  getSuggestHouse (state) {
    return state.api + state.common.GET.suggestHouse
  },
  checkAddressIsDeliveryByString (state) {
    return state.api + state.common.GET.checkAddressIsDeliveryByString
  },
  // Orders
  getHistoryOrders (state) {
    return state.api + state.orders.GET.history
  },
  getActiveOrders (state) {
    return state.api + state.orders.GET.active
  },
  getThankPageDetails: state => (id) => {
    return (
      state.api + state.orders.GET.getThankPageDetails.replace(/\{id\}/g, id)
    )
  },
  getOrderById: state => (id) => {
    return state.api + state.orders.GET.getOrderById.replace(/\{id\}/g, id)
  },
  repeatOrder: state => (id) => {
    return state.api + state.orders.POST.repeatOrder.replace(/\{id\}/g, id)
  },
  // Information
  getSliders (state) {
    return state.api + state.information.GET.sliders
  },
  getPolygons (state) {
    return state.api + state.information.GET.polygons
  },
  getPromotions (state) {
    return state.api + state.information.GET.promotions
  },
  getPromotionsSingle: state => (id) => {
    return (
      state.api + state.information.GET.promotionsSingle.replace(/\{id\}/g, id)
    )
  },
  getNews (state) {
    return state.api + state.information.GET.news
  },
  getNewsSingle: state => (id) => {
    return state.api + state.information.GET.newsSingle.replace(/\{id\}/g, id)
  },
  getFavoritesList (state) {
    return state.api + state.favorites.GET.list
  },
  addFavoritesItem: state => (id) => {
    return state.api + state.favorites.POST.addMenuItem.replace(/\{id\}/g, id)
  },
  deleteFavoritesItem: state => (id) => {
    return (
      state.api + state.favorites.POST.deleteMenuItem.replace(/\{id\}/g, id)
    )
  },

  getRegionsList (state) {
    return state.api + state.information.GET.regions
  },

  getRegionItem (state) {
    return state.api + state.information.GET.regionSingle
  },

  getRegionById: state => (id) => {
    return state.api + state.information.GET.regionById.replace(/\{id\}/g, id)
  },

  getPickupPoints (state) {
    return state.api + state.information.GET.pickupPoints
  },

  getSinglePickupPointBySlug: state => (slug) => {
    return (
      state.api +
      state.information.GET.pickupPointsSingle.replace(/\{slug\}/g, slug)
    )
  },

  getPageMeta (state) {
    return state.api + state.seo.GET.meta
  },

  getDeliveryMap (state) {
    return state.api + state.geo.GET.deliveryMap
  },

  getDeliveryOptions (state) {
    return state.api + state.information.GET.deliveryOptions
  },
}

export default {
  namespaced: true,
  state,
  getters,
}
