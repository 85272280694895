export default (context/* { app } */) => {
  /*
   ** Only run on client-side and only in production mode
   */
  // if (process.env.NODE_ENV !== 'production' || !process.browser) {
  //   return
  // }
  console.log('------- _redirectToRegionFromCookie -------')

  const cookieRegionId = context.$cookies.get('region_id')
  const currentRegionId = context.$region.getCurrentRegionId()
  console.table({ cookieRegionId, currentRegionId })

  if (cookieRegionId && cookieRegionId !== currentRegionId) {
    context.store.dispatch('region/fetchRegionById', cookieRegionId)
      .then((targetRegion) => {
        // console.log({ targetRegion })
        if (targetRegion.slug) {
          context.$region.redirectToRegion(targetRegion)
        }
      })
      .catch((e) => {
        console.log({ error: e })
        if (e?.response?.status === 404) {
          context.$cookies.set('region_id', '')
        }
      })
  }

  console.log('------- end _redirectToRegionFromCookie -------')
}
