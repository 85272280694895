import { DateTime, Settings } from 'luxon'
import { cookie } from '~/utils/cookie'

export default class RegionService {
  multiRegionMode = true
  #regionKey;
  regionNotFound
  defaultSocials = {
    vk: 'https://vk.com/setsushi',
  }

  constructor (context) {
    this.nuxt = context
    this.appConfig = context.$config
    this.errorHandler = context.error
    this.defaultHost = context.$config.appHost || 'setsushi.fakt-crm.com' // this.nuxt.isDev ? 'localhost' : 'setsushi.ru' // this.appConfig.appHost

    console.log({ defaultHost: this.defaultHost })
  }

  /* ssr request, вызываем метод при инициализации стора на сервере (nuxtServerInit) */
  async init (request) {
    this.request = request

    this.regionKey = this._extractRegionKeyFromHost()

    /* console.log({
      regionKey: this.regionKey,
      isSubDomain: this.isSubDomain(),
    }) */

    const res = await (this.isSubDomain() ? this._fetchCurrentRegionInfo() : this._fetchDefaultRegionInfo())

    return res
  }

  /* Detect region by request host */
  _extractRegionKeyFromHost () {
    const host = this.request.headers.host.split(':')[0]
    const defaultHost = this.nuxt.$config.appHost || 'setsushi.fakt-crm.com' // this.nuxt.isDev ? 'localhost' : 'setsushi.ru' // this.appConfig.appHost
    this.defaultHost = defaultHost
    const regexp = new RegExp(`^(www\\.)?([\\w_-]*)?\\.?${defaultHost}$`, 'i')
    const matches = regexp.exec(host)
    /* console.log({
      host1: host,
      defaultHost,
    }) */
    if (matches) {
      return matches[2]
    } else {
      return ''
    }
    // return matches[2] ?? ''
  }

  _fetchCurrentRegionInfo () {
    return this.nuxt.store.dispatch('region/fetchRegionInfo', {
      key: this.regionKey,
    })
      .catch((e) => {
        console.log('_fetchCurrentRegionInfo error ----->', this.regionKey)
        // console.log(e)
        this.nuxt.$cookies.set('region_id', null)
        // this.nuxt.$sentry.captureException(e)
        this.nuxt.store.commit('region/setReginNotFoundError')
        return this.errorHandler({ statusCode: 404, message: 'Region not found', type: 'regionError' })
      })
  }

  _fetchDefaultRegionInfo () {
    return this.nuxt.store.dispatch('region/fetchRegionInfo', {
      isMain: true,
    })
      .catch((e) => {
        console.log('_fetchDefaultRegionInfo error ----->')
        // console.log(e)
        // this.nuxt.$sentry.captureException(e)
        this.nuxt.$cookies.set('region_id', null)
        this.nuxt.store.commit('region/setReginNotFoundError')
        return this.errorHandler({ statusCode: 500, message: 'Ошибка получения региона... Обновите страницу или попробуйте позднее' })
      })
  }

  /** ************* GETTERS ***************/

  isMultiRegionMode () {
    return this.multiRegionMode
  }

  isSubDomain () {
    return !!this.regionKey
  }

  getDefaultRegionBaseUrl () {
    return `//${this.defaultHost}`
  }

  getRegionDomain (region) {
    let domain = this.nuxt.isDev ? 'http://' : 'https://'

    if (!region.isMain) {
      domain += `${region.slug}.`
    }
    domain += this.defaultHost

    if (this.nuxt.isDev) {
      domain += ':' + (process.env.PORT || 3000)
    }
    return domain
  }

  getRedirectUrl (region) {
    return `${this.getRegionDomain(region)}${this.nuxt.route.fullPath}`
  }

  get (key, defaultValue = null) {
    return this.nuxt.store.getters['region/getRegionField'](key, defaultValue)
  }

  getCurrentRegionId () {
    return this.get('id')
  }

  getKey () {
    return this.regionKey
  }

  getPhone () {
    if (this.get('phone')) {
      return this.get('phone')
    }
    return '8 800 500 02 20'
  }

  getSocialPath (key) {
    return this.get(key) || this.defaultSocials[key]
  }

  getSocials () {
    const defaultSocials = [
      { key: 'vk', path: '', icon: 'vk', color: '#0077FF' },
    ]

    return defaultSocials.map((item) => {
      item.path = this.getSocialPath(item.key)
      return item
    })
  }

  /** ************* Actions ***************/

  redirectToRegion (region) {
    if (region.id !== this.getCurrentRegionId()) {
      window.location.replace(this.getRedirectUrl(region))
      // return this.nuxt.redirect(this.getRedirectUrl(region))
    }
  }

  changeCurrentRegion (region) {
    return new Promise((resolve, reject) => {
      // cookie.set('region_id', region.id, 60, '/', '.' + this.defaultHost)
      this.nuxt.$cookies.set('region_id', region.id)

      try {
        if (this.nuxt.store.getters['auth/loggedIn']) {
          this.nuxt.store.dispatch('client/updateProfile', { regionId: region.id })
            .then(() => this.redirectToRegion(region))
        } else {
          this.redirectToRegion(region)
        }
      } catch (e) {
        reject(e)
      }

      resolve(region)
    })
  }
}
